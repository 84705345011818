<template>
  <section>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->

    <h2 class="titulo-links-escolha" ref="topo">Conteúdo Aplicado</h2>

    <div class="row" style="margin-bottom:20px;">
      <div class="col-md-3">
        <div class="pm-card">
          <div class="col-md-12" style="margin-bottom:20px;">
            <label for="escola"> Selecione uma escola</label>
            <select class="p-inputtext p-component" v-model="escola_id" @change="limparCampos()"
              style="appearance: revert !important;width: 100% !important;" >
              <option  value="" disabled selected> -- Escolha uma Escola -- </option>
              <option v-for="n in escolas" :key="n" :value="n.id" >{{n.nome}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-3"  v-if="escola_id">
        <div class="pm-card">
          <div class="col-md-12" style="margin-bottom:20px;">
            <label for="escola"  value="" disabled selected> Selecione um segmento</label>
            <select class="p-inputtext p-component" v-model="segmentoInfo"
              style="appearance: revert !important;width: 100% !important;" @change="listaSerie(segmentoInfo)">
              <option value="" disabled selected> -- Escolha um Segmento -- </option>
              <option v-for="s in segmentoALL" :key="s" :value="s.id" >{{s.nome}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-3" v-if="segmentoInfo">
        <div class="pm-card">
          <div class="col-md-12" style="margin-bottom:20px;">
            <label for="escola"  value="" disabled selected> Selecione um período</label>
            <select class="p-inputtext p-component" v-model="serieInfo"
              style="appearance: revert !important;width: 100% !important;" @change="listaTurmas()" >
              <option value="" disabled selected> -- Escolha um Período -- </option>
              <option v-for="a in serieALL" :key="a" :value="a.id" >{{a.nome}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-3" v-if="serieInfo">
        <div class="pm-card">
          <div class="col-md-12" style="margin-bottom:20px;">
            <label for="escola"  value="" disabled selected> Selecione uma turma</label>
            <select class="p-inputtext p-component" v-model="turma_id" @change="listaDisciplina();"
              style="appearance: revert !important;width: 100% !important;" >
              <option  value="" disabled selected> -- Escolha uma Turma -- </option>
              <option v-for="n in turmaALL" :key="n" :value="n.id" >{{n.nome}}</option>
            </select>
          </div>
        </div>
      </div>

    </div>

    <div class="pm-card" >
      <div class="row">
        <div class="col-md-3" v-if="turma_id">
          <label>Selecione uma Disciplina:</label>
          <select class="p-inputtext p-component"
            v-model="disciplina_id" @change="conteudoAplicado()"
            style="appearance: revert !important;width: 100% !important;"
          >
            <option   :value="n.id" v-for="n in disciplinaALL" :key="n.id">{{n.nome}}</option>
          </select>
        </div>

        <div class="col-md-3" v-if="turma_id">
          <label>Selecione um Mês:</label>
          <select class="p-inputtext p-component"
            v-model="mes" @change="conteudoAplicado()"
            style="appearance: revert !important;width: 100% !important;"
          >
            <option  :value="n.id" v-for="n in meses" :key="n.id">{{n.nome}}</option>
          </select>
        </div>
      </div>

      <br>

      <pm-DataTable :value="item"   dataKey="id" :rowHover="true" filterDisplay="menu"
        responsiveLayout="scroll">

        <pm-Column field="dia" sortable header="Dia" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
        <pm-Column field="texto" header="Conteudo do dia" style="font-size: 14px;vertical-align:middle !important"></pm-Column>


        <pm-Column field="id"  headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
          <template #body="{data}">
            <pm-Button :disabled="!anoSitucao" type="button" @click="editarAtividadePedagociaOption(data)" icon="pi pi-pencil"
              label="Editar" title="Editar"  class="p-button-sm btn-color;table_style"></pm-Button>
            <pm-Button  :disabled="!anoSitucao" title="Apagar" type="button" @click="deletarConteudo(data.id)" label="Apagar"
              icon="pi pi-times-circle" class="p-button-danger p-button-sm btn-color;table_style" style="margin-left:5px"></pm-Button>
          </template>
        </pm-Column>

      </pm-DataTable>
    </div>




    <pm-Dialog header="Atividade lecionada" v-model:visible="displayMaximizable" :style="{width: '100vw'}" :maximizable="true" :modal="true">
      <div class="p-fluid formgrid grid" >
        <div class="field col-12 md:col-4">
          <label for="data">Informe a data:</label>
          <pm-InputText  v-model="infoParaEnviar.data" type="date"/>
        </div>
        <div class="field col-12 md:col-12">
          <label for="atividade">Informe a atividade lecionada:</label>
          <pm-Textarea  rows="12"  v-model="infoParaEnviar.texto"/>
        </div>
      </div>
      <template #footer>
        <pm-Button label="Fechar" icon="pi pi-times" @click="dialogAction" class="p-button-text"/>
        <pm-Button  v-if="infoParaEnviar.texto" label="Editar" icon="pi pi-check" @click="editarAtividade" autofocus />
      </template>
    </pm-Dialog>












  </section>
</template>

<script>
import axios from "axios";

import { Escola } from "@/class/escolas";
import { Turma } from "@/class/turma";
import { DisciplinaEscolar } from "@/class/disciplina";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";
import { LoginInfo } from "@/class/login";

export default {
  components: {},
  name: "conteudo",
  data() {
    const meses = [{id:1,nome:'Janeiro'},{id:2,nome:'Fevereiro'},{id:3,nome:'Março'},{id:4,nome:'Abril'},{id:5,nome:'Maio'},{id:6,nome:'Junho'},
    {id:7,nome:'Julho'},{id:8,nome:'Agosto'},{id:9,nome:'Setembro'},{id:10,nome:'Outubro'},{id:11,nome:'Novembro'},{id:12,nome:'Dezembro'}];
    return {
      meses,
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Conteúdo Aplicado'},
      ],
      item: [],
      escola_id: null,
      segmentoInfo:null,
      serieInfo:null,
      turma_id:null,
      disciplina_id:0,
      mes:0,

      escolas: [],
      segmentoALL: [],
      serieALL: [],
      turmaALL: [],
      disciplinaALL:[],
      perPage: 50,
      currentPage: 1,
      acesso : {
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      },
      infoParaEnviar: {
        id:'',
        data:'',
        texto:''
      },
      displayMaximizable:false,
      submitted:false,
      diretor:0,
      coordenador:0,
      funcao:0,
      who:[],
      anoSitucao:0,
      anoSelecionado:0
    };
  },

  async beforeMount() {
    this.anoSelecionado = parseInt(sessionStorage.getItem("anoSelecionado"));
    this.anoSitucao = parseInt(sessionStorage.getItem("anoSitucao"));
    await this.logado();
    const date = new Date();
    this.mes = date.getMonth() + 1
    this.listarEscolas();
  },

  methods: {
    async editarAtividade() {
        try {
          const s = this.infoParaEnviar.data.split("-");
          const nv = {
              id:  this.infoParaEnviar.id,
              texto: this.infoParaEnviar.texto,
              dia:  parseInt(s[2]),
              mes:  parseInt(s[1]),
          }
          const data = await PlanejamentoPedagogico.editarPlanejamentoProf(nv);
          this.$vaToast.init({
          message: "Planejamento editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'success'
          });
          this.dialogAction();
          this.conteudoAplicado();
        }
        catch(e) {
          if(e.response.data.validacao){
            let a = [];
            a = e.response.data.validacao;

            Object.entries(a).forEach(([key, value]) => {
                setTimeout(() => {
                this.$vaToast.init({
                    message: value,
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    duration: 3500,
                    fullWidth: false,
                    color: 'danger'
                });
                }, 500);
            });

          }
          else{
            let a = [];
            a = e.response.data.erro;
            setTimeout(() => {
              this.$vaToast.init({
                  message: a,
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3500,
                  fullWidth: false,
                  color: 'danger'
              });
            }, 500);
          }
        }

    },

    dialogAction() {
        this.infoParaEnviar.data = '';
        this.infoParaEnviar.texto = '';
        this.infoParaEnviar.id = '';
        this.displayMaximizable = !this.displayMaximizable;
    },
    async editarAtividadePedagociaOption(atividade) {
      if (atividade.mes.length == 1)
        {atividade.mes = '0' + atividade.mes;}

      if (atividade.dia.length == 1)
        {atividade.dia = '0' + atividade.dia;}

      const data = atividade.ano + '-' + atividade.mes.toString().padStart(2, '0') + '-' + atividade.dia.toString().padStart(2, '0');

      this.infoParaEnviar.id = atividade.id;
      this.infoParaEnviar.data = data;
      this.infoParaEnviar.texto = atividade.texto;
      // console.log(this.infoParaEnviar);
      this.displayMaximizable = !this.displayMaximizable;
    },
    async editarConteudo(id) {
    },
    async deletarConteudo(id) {
      if (confirm("Você realmente deseja excluir o conteúdo selecionado?")) {
        try {
          const data = await PlanejamentoPedagogico.deletarPlanejamentoProf(id);
          this.$vaToast.init({
            message: "Apagado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
            color: 'success'
          });
          this.conteudoAplicado();
        }
        catch(e) {
          if(e.response.data.validacao){
            let a = [];
            a = e.response.data.validacao;

            Object.entries(a).forEach(([key, value]) => {
                setTimeout(() => {
                this.$vaToast.init({
                    message: value,
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    duration: 3500,
                    fullWidth: false,
                    color: 'danger'
                });
                }, 500);
            });

          }
          else{
            let a = [];
            a = e.response.data.erro;
            setTimeout(() => {
              this.$vaToast.init({
                  message: a,
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3500,
                  fullWidth: false,
                  color: 'danger'
              });
            }, 500);
          }
        }
      }
    },
    async conteudoAplicado() {
      const ano = sessionStorage.getItem("anoSelecionado");
      const playload = {
        turma_id: this.turma_id,
        disciplina_id: this.disciplina_id,
        ano: ano,
        mes: this.mes,
      }
      const data = await PlanejamentoPedagogico.obtemPlanejamentoMensal(playload);
      this.item = data.data;
    },

    limparCampos(){
      this.segmentoALL = [];
      this.serieALL = [];
      this.turmaALL = [];

      this.turma_id= null;
      this.segmentoInfo = null;
      this.serieInfo = null;

      this.buscarSegmentoALL();
    },

    async listaDisciplina(){
      this.disciplina_id = 0;
      const data = await DisciplinaEscolar.obtemTodosSemId1Turma(this.turma_id);
      this.disciplinaALL = [];
      for (const el of data.data) {
        if(el.na_turma == 1){
          const novo = {
            id: el.id,
            nome: el.nome,
            status: el.status
          }
          this.disciplinaALL.push(novo);
        }
      }
    },

    async logado() {
      const token = sessionStorage.getItem("token");
      const whoiam = await LoginInfo.WhoIam(token);

      this.funcao = whoiam.data.funcao;
      this.who = whoiam.data;
    },

    async listarEscolas(){
      if(this.funcao === 1 || this.funcao === 2 || this.funcao === 7){
        const esc = await Escola.obtemTodos();
        for (const el of esc.data) {
          const novo = {
            id_: el.id,
            id: el.id,
            nome: el.nome,
          };
          this.escolas.push(novo);
        };
      }

      //diretores
      if(this.funcao == 5 || this.funcao == 3){
        if(this.who.diretor == 1){

          await axios.get('/diretor/servidor/'+this.who.usuario.id+'/ano/'+this.anoSelecionado)
            .then((resp) => {
              this.diretor = resp.data.id
            }).catch((err) => {});


          const data = await Escola.obtemUm(this.diretor.escola);
          this.escolas.push(data.data)
        }
      }

      //coordenadores
      if(this.funcao === 4){
        const storageEscolas = sessionStorage.getItem("escolas_c");
        if(!storageEscolas){
          await axios.get('/coordenador/servidor/'+this.who.usuario.id+'/ano/'+this.anoSelecionado)
            .then((resp) => {
              this.coordenador = resp.data
              sessionStorage.setItem("coordenador_c",JSON.stringify(this.coordenador));

            }).catch((err) => {});

          const escolas = this.coordenador.esc;
          for (let i = 0; i < escolas.length; i++) {
            const data = await Escola.obtemUm(escolas[i]);
            this.escolas.push(data.data)
          }
          sessionStorage.setItem("escolas_c",JSON.stringify(this.escolas));
        }else{
          this.escolas = JSON.parse(storageEscolas);

          const storageCoordenador = sessionStorage.getItem("coordenador_c");
          this.coordenador = JSON.parse(storageCoordenador);
        }
      }

    },

    async buscarSegmentoALL() {
      if(this.funcao === 4){
        const data = await SegmentoEscolar.obtemSegmentosEsp();
        this.segmentoALL = data.data;
      }else{
        const data = await SegmentoEscolar.obtemTodos();
        this.segmentoALL = data.data;
      }
    },

    async listaSerie (id) {
      this.turma_id = null;
      this.serieInfo = null;

      const dataseg = await SegmentoEscolar.obtemUm(id);
      this.tipo_segmento = dataseg.data.tipo;

      const data = await SegmentoEscolar.listarSegmentosPorSerie(id);

      this.serieALL = data.data;
      return this.seriesALL;
    },


    async listaTurmas(){
      const play = {
        'escola':this.escola_id,
        'serie': this.serieInfo,
        'segmento': this.segmentoInfo,
      };

      const data = await Turma.pesqTurmaPorSerieSegmento(play);
      this.turmaALL = data.data;

      const dat = await SeriesEscolar.obtemUm(this.serieInfo);
      this.selectSerie = dat.data;
      if(this.tipo_segmento != 2){
        this.serie_tipo = dat.data.tipo;
      }else{
        this.serie_tipo = 0;
      }
    },
  },

};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
